/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dark:hover,
.ant-btn-dark:focus {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.75);
  border-color: rgba(52, 58, 64, 0.1);
}
.ant-btn-dark:active,
.ant-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}
.ant-btn-background-ghost.ant-btn-dark {
  color: #343a40;
  background: transparent;
  border-color: #343a40;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
  color: rgba(52, 58, 64, 0.75);
  background: transparent;
  border-color: rgba(52, 58, 64, 0.75);
}
.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
  color: #343a40;
  background: transparent;
  border-color: #1d2124;
}
.ant-btn-lightdark {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.75);
  border-color: rgba(108, 117, 125, 0.1);
}
.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.ant-btn-background-ghost.ant-btn-lightdark {
  color: #6c757d;
  background: transparent;
  border-color: #6c757d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
  color: rgba(108, 117, 125, 0.75);
  background: transparent;
  border-color: rgba(108, 117, 125, 0.75);
}
.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
  color: #6c757d;
  background: transparent;
  border-color: #545b62;
}
.ant-btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 0.1);
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745;
  background: transparent;
  border-color: #28a745;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: rgba(40, 167, 69, 0.75);
  background: transparent;
  border-color: rgba(40, 167, 69, 0.75);
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745;
  background: transparent;
  border-color: #1e7e34;
}
.ant-btn-warning {
  color: #fff;
  background-color: #eca52b;
  border-color: #eca52b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: rgba(236, 165, 43, 0.75);
  border-color: rgba(236, 165, 43, 0.1);
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff;
  background-color: #d18b13;
  border-color: #d18b13;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b;
  background: transparent;
  border-color: #eca52b;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: rgba(236, 165, 43, 0.75);
  background: transparent;
  border-color: rgba(236, 165, 43, 0.75);
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b;
  background: transparent;
  border-color: #d18b13;
}
.ant-btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: rgba(23, 162, 184, 0.75);
  border-color: rgba(23, 162, 184, 0.1);
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #117a8b;
  border-color: #117a8b;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8;
  background: transparent;
  border-color: #17a2b8;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: rgba(23, 162, 184, 0.75);
  background: transparent;
  border-color: rgba(23, 162, 184, 0.75);
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8;
  background: transparent;
  border-color: #117a8b;
}
.disable-animation:after {
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-typography blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.ant-typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.ant-typography blockquote p {
  display: inline;
}
:root {
  --primary-color: #002e7d;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  background: #002e7d !important;
}
.ant-upload-list-picture-card {
  margin-top: 8px;
}
.ant-form-item-label {
  white-space: normal;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #0a0a0a;
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #002e7d;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #002e7d;
}
.ant-tag-checkable:active {
  background-color: #001d57;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #28a745;
  background: #d8e6d9;
  border-color: #93cc9a;
}
.ant-tag-processing {
  color: #17a2b8;
  background: #e6f7f7;
  border-color: #85dbde;
}
.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #eca52b;
  background: #fffcf0;
  border-color: #ffe8a8;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dark:hover,
.ant-btn-dark:focus {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.75);
  border-color: rgba(52, 58, 64, 0.1);
}
.ant-btn-dark:active,
.ant-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}
.ant-btn-background-ghost.ant-btn-dark {
  color: #343a40;
  background: transparent;
  border-color: #343a40;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
  color: rgba(52, 58, 64, 0.75);
  background: transparent;
  border-color: rgba(52, 58, 64, 0.75);
}
.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
  color: #343a40;
  background: transparent;
  border-color: #1d2124;
}
.ant-btn-lightdark {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.75);
  border-color: rgba(108, 117, 125, 0.1);
}
.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.ant-btn-background-ghost.ant-btn-lightdark {
  color: #6c757d;
  background: transparent;
  border-color: #6c757d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
  color: rgba(108, 117, 125, 0.75);
  background: transparent;
  border-color: rgba(108, 117, 125, 0.75);
}
.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
  color: #6c757d;
  background: transparent;
  border-color: #545b62;
}
.ant-btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 0.1);
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745;
  background: transparent;
  border-color: #28a745;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: rgba(40, 167, 69, 0.75);
  background: transparent;
  border-color: rgba(40, 167, 69, 0.75);
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745;
  background: transparent;
  border-color: #1e7e34;
}
.ant-btn-warning {
  color: #fff;
  background-color: #eca52b;
  border-color: #eca52b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: rgba(236, 165, 43, 0.75);
  border-color: rgba(236, 165, 43, 0.1);
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff;
  background-color: #d18b13;
  border-color: #d18b13;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b;
  background: transparent;
  border-color: #eca52b;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: rgba(236, 165, 43, 0.75);
  background: transparent;
  border-color: rgba(236, 165, 43, 0.75);
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b;
  background: transparent;
  border-color: #d18b13;
}
.ant-btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: rgba(23, 162, 184, 0.75);
  border-color: rgba(23, 162, 184, 0.1);
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #117a8b;
  border-color: #117a8b;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8;
  background: transparent;
  border-color: #17a2b8;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: rgba(23, 162, 184, 0.75);
  background: transparent;
  border-color: rgba(23, 162, 184, 0.75);
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8;
  background: transparent;
  border-color: #117a8b;
}
.disable-animation:after {
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-typography blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.ant-typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.ant-typography blockquote p {
  display: inline;
}
:root {
  --primary-color: #002e7d;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  background: #002e7d !important;
}
.ant-upload-list-picture-card {
  margin-top: 8px;
}
.ant-form-item-label {
  white-space: normal;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #28a745;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #0a0a0a;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #28a745;
}
.ant-progress-status-success .ant-progress-text {
  color: #28a745;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #28a745;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #28a745;
}
@-webkit-keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}

